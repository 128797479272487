import React, {
  useCallback,
  useEffect,
  useMemo,
  useState,
  useRef,
} from 'react';
import { Table } from 'react-bootstrap';
import { useAppContext } from 'contexts/AppContext';
import { useBudgetQueries } from 'utils/api/queries';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';
import { useQueryClient } from '@tanstack/react-query';
import PropTypes from 'prop-types';
import ItemRow from './ItemRow';
import {
  calculateDetailsTotal,
  deleteSwimlaneDetailsItem,
  getColumnConf,
  getDetailsSheet,
  handlePartialDataUpdate,
} from 'utils/common';
import { debounce, formatCurrency, sortArray } from 'utils/helper';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './styles.scss';
import { toast } from 'react-toastify';

// const getItemStyle = (isDragging, draggableStyle) => ({
//   userSelect: 'none',
//   padding: '16px',
//   margin: '0 0 8px 0',
//   width: '300px', // Fixed width for the items
//   background: isDragging ? 'lightgreen' : 'grey',
//   ...draggableStyle,
// });

const DetailedView = ({ setNavWidth, onKeyUp }) => {
  const {
    isUserLoggedIn,
    unitTypes,
    useReorderDetailSheet,
    useReorderDetailLineItemSheet,
  } = useAppContext();
  const queryClient = useQueryClient();
  const {
    showDetailedView,
    selectedBudget,
    createSwimlaneDetailsMutation,
    updateSwimlaneDetailsMutation,
    updateSwimlaneCosts,
    refetchBudgetId,
    hasEditAccess,
    deleteSwimlaneDetailsMutation,
  } = useHomeScreenContext();
  const mutationCache = useRef({});
  const [hiddenColumn, setHiddenColumn] = useState({ comment: true });
  const [caretPoition, setCaretPosition] = useState();
  const { level, selectedItem, swimlane } = showDetailedView;
  const [detailsRows, setDetailsRow] = useState({ [selectedItem?.id]: [] });
  const [detailsTotal, setDetailsTotal] = useState(0);
  const [selectedSwimlane, setSelectedSwimlane] = useState({});
  const [focusEl, setFocusEl] = useState(null);
  const type =
    level === 0 ? 'swimlane' : level === 1 ? 'line-item' : 'line-item-children';
  const id =
    level === 0
      ? selectedBudget?.budget
      : level === 1
      ? selectedItem?.swimlane
      : selectedItem?.parent;
  const { data, refetch } = useBudgetQueries.useSwimlaneQuery({
    enabled: Boolean(isUserLoggedIn && type && id),
    payload: {
      id,
      type,
    },
  });

  const details = getDetailsSheet(
    data?.data?.find(item => item?.id === selectedItem?.id),
    type
  );
  console.log('details', details);

  // const lastObject = details[details?.length - 1];
  // const lastObjectId = parseInt(lastObject?.id);
  // console.log('lastObjectId', lastObjectId);

  const toggleColumn = column => () => {
    setHiddenColumn({ ...hiddenColumn, [column]: !hiddenColumn[column] });
    setNavWidth?.();
  };

  const expandColumn = column => () => {
    setHiddenColumn({ ...hiddenColumn, [column]: false });
    setNavWidth?.();
  };

  const callHiddenCommentParent = () => {
    setHiddenColumn({ ...hiddenColumn, comment: false });
    setNavWidth?.();
  };
  const columns = getColumnConf(selectedBudget, unitTypes, toggleColumn);
  // const generateUniqueId = () => {
  //   return `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`;
  // };
  const addEmptyRow = () => {
    const index =
      (Math.max(...detailsRows[selectedItem?.id].map(row => row.row_order)) ||
        0) + 1;
    setDetailsRow({
      ...detailsRows,
      [selectedItem?.id]: [
        ...detailsRows[selectedItem?.id],
        {
          // id: generateUniqueId(),
          // id: `${Date.now()}-${Math.random().toString(36).substr(2, 9)}`,
          row_id: `row-${type}-${selectedItem?.id}-${index}`,
          row_order: index,
        },
      ],
    });
  };

  const prefillDecimals = useCallback(
    rowData =>
      Object.keys(rowData).reduce((acc, fieldKey) => {
        const isDecimal = columns.some(
          col => col.key === fieldKey && col.dataType === 'decimal'
        );
        acc[fieldKey] ||= isDecimal ? 0 : acc[fieldKey];
        return acc;
      }, rowData),
    []
  );

  const clearMutateCache = (rowId, mutateCache) => {
    return Object.keys(mutateCache || {}).reduce((acc, rowIndex) => {
      if (id !== rowId) {
        return { ...acc, [rowIndex]: mutateCache?.[id] };
      }
      return acc;
    }, {});
  };

  const handleAddRow = useMemo(
    () =>
      debounce((rowData, totalCost) => {
        const mutateMethod = rowData?.id
          ? updateSwimlaneDetailsMutation
          : createSwimlaneDetailsMutation;
        const payload = {
          details: {
            ...(!rowData?.id
              ? {
                  item: '',
                  position: rowData?.row_order - 1,
                }
              : null),
            ...prefillDecimals(rowData),
          },
          selectedItem,
          swimlane,
          type,
        };

        if (!mutationCache.current[rowData.row_id]?.inProgress) {
          mutationCache.current = {
            ...mutationCache.current,
            [rowData.row_id]: {
              inProgress: 1,
              selectedItemId: selectedItem?.id,
            },
          };
          mutateMethod.mutate(payload, {
            onSuccess: response => {
              const newData = response.data || rowData;
              const updatesInProgress = mutationCache.current;

              // if there is another request in queue, trigger that
              if (updatesInProgress[rowData.row_id]?.update) {
                updatesInProgress[rowData.row_id]?.update?.(newData?.id);
              } else {
                mutationCache.current = clearMutateCache(
                  rowData.row_id,
                  updatesInProgress
                );
                refetch?.();
                refetchBudgetId?.();

                if (!rowData?.id) setFocusEl(document.activeElement.name);

                setDetailsRow(prev => ({
                  ...prev,
                  [selectedItem?.id]: [
                    ...handlePartialDataUpdate(
                      prev[selectedItem?.id],
                      [{ ...rowData, ...newData }],
                      selectedItem,
                      type
                    ),
                  ],
                }));
              }
            },
            onError: () => {
              const updatesInProgress = mutationCache.current;
              if (updatesInProgress[rowData.row_id]?.update) {
                updatesInProgress[rowData.row_id]?.update?.();
              } else {
                mutationCache.current = clearMutateCache(
                  rowData.row_id,
                  updatesInProgress
                );
              }
            },
          });
        }
        updateSwimlaneCosts(totalCost, selectedItem);
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [prefillDecimals, selectedItem, swimlane, type]
    //detailsRows
  );

  const addRow = useCallback(
    rowData => {
      let newDetails = [...detailsRows[selectedItem?.id]];
      const rowItems = [];

      if (
        rowData.row_id &&
        detailsRows[selectedItem?.id]?.some(
          item => item?.row_id === rowData?.row_id
        )
      ) {
        newDetails = newDetails?.map(item => {
          if (rowData?.row_id && item?.row_id === rowData?.row_id) {
            rowItems.push(rowData);
            return rowData;
          }
          return item;
        });
      } else {
        rowItems.push(rowData);
        newDetails = [...newDetails, rowData];
      }

      if (rowItems?.length) {
        setDetailsRow(prev => ({
          ...prev,
          [selectedItem?.id]: [
            ...handlePartialDataUpdate(
              prev[selectedItem?.id],
              [{ ...rowData }],
              selectedItem,
              type
            ),
          ],
        }));
        const totalCost = calculateDetailsTotal(newDetails, setDetailsTotal);
        const updatesInProgress = mutationCache.current;

        if (updatesInProgress?.[rowData.row_id]?.inProgress) {
          mutationCache.current = {
            ...mutationCache.current,
            [rowData.row_id]: {
              inProgress: 1,
              selectedItemId: selectedItem?.id,
              update: newId => {
                const currentState = mutationCache.current;
                delete mutationCache.current?.[rowData.row_id];
                mutationCache.current = {
                  ...currentState,
                  [rowData.row_id]: {
                    inProgress: false,
                    newId,
                  },
                };
                handleAddRow(
                  { ...rowData, ...(newId ? { id: newId } : null) },
                  totalCost
                );
              },
            },
          };
        } else {
          const newId = updatesInProgress?.[rowData.row_id]?.newId;
          handleAddRow(
            { ...rowData, ...(newId ? { id: newId } : null) },
            totalCost
          );
        }
      }
    },
    [handleAddRow, detailsRows]
  );

  const reorderRows = rows => {
    console.log('rows', rows);
    const payload = [];
    rows.forEach((item, index) => {
      if (item?.id) {
        payload.push({ id: item.id, position: index });
      }
    });

    const isSwimlaneItem = rows[0]?.row_id?.includes('row-swimlane');
    const isLineItem = rows[0]?.row_id?.includes('row-line-item');

    if (isSwimlaneItem) {
      useReorderDetailSheet.mutate(
        { detail_sheet_items: payload },
        {
          onSuccess: response => {
            console.log('Swimlane Reordered:', response);
            refetch?.();
            refetchBudgetId?.();
          },
          onError: () => {
            // toast.error('Error in reordering swimlane items');
          },
        }
      );
    } else if (isLineItem) {
      useReorderDetailLineItemSheet.mutate(
        { detail_lineitem_sheet_items: payload },
        {
          onSuccess: response => {
            console.log('Line Item Reordered:', response);
            refetch?.();
            refetchBudgetId?.();
          },
          onError: () => {
            // toast.error('Error in reordering line items');
          },
        }
      );
    }
  };

  const handleRowAction = useCallback(
    item => action => {
      console.log('actions', action);
      if (action === 'delete-item') {
        const deletedId = detailsRows[selectedItem?.id]?.find(
          row => row?.row_id === item?.row_id
        )?.id;
        console.log('deletedId', item?.id, deletedId);
        const payload = { type, id: item?.id };
        if (item?.id) {
          deleteSwimlaneDetailsMutation.mutate(payload, {
            onSuccess: async () => {
              deleteSwimlaneDetailsItem(queryClient, item, selectedItem, {
                type,
                id,
              });
              refetch?.();
              refetchBudgetId?.();
            },
          });
        }

        const newDetails =
          detailsRows[selectedItem?.id]?.filter(row => row?.id !== item?.id) ||
          [];
        // setDetailsRow(prev => ({
        //   ...prev,
        //   [selectedItem?.id]: [...newDetails],
        // }));
        setDetailsRow(prev => {
          const adjustedRows = newDetails.map((row, i) => ({
            ...row,
            position: i,
            row_order: i + 1,
            row_id: `row-${type}-${selectedItem?.id}-${i + 1}`,
          }));
          // Reorder rows based on the new updated list
          reorderRows(adjustedRows);
          return {
            ...prev,
            [selectedItem?.id]: adjustedRows,
          };
        });
        const totalCost = calculateDetailsTotal(newDetails, setDetailsTotal);
        updateSwimlaneCosts(totalCost, selectedItem);
      }
      if (action === 'duplicate-item') {
        const index = detailsRows[selectedItem?.id].findIndex(
          row => row?.row_id === item?.row_id
        );
        console.log('index@', index);
        const payload = {
          ...item,
          id: null,
          rate: item?.rate?.replace(/,/g, ''),
        };
        console.log('duplicate-item', payload);
        // const newRowData = {
        //   ...payload,
        //   id: null,
        //   // position: index + 1,
        //   // position: detailsRows[selectedItem?.id].length - 1,
        //   // row_order: detailsRows[selectedItem?.id].length,
        //   // row_id: `row-${type}-${selectedItem?.id}-${
        //   //   detailsRows[selectedItem?.id].length
        //   // }`,
        // };
        console.log('newRowData', payload);
        createSwimlaneDetailsMutation.mutate(
          {
            details: payload,
            selectedItem,
            swimlane,
            type,
          },
          {
            onSuccess: async response => {
              const newRow = response.data || payload;
              setDetailsRow(prev => {
                const updatedRows = [...prev[selectedItem?.id]];
                updatedRows.splice(index + 1, 0, newRow);

                // Adjust positions after inserting the new row
                const adjustedRows = updatedRows.map((row, i) => {
                  if (row?.id) {
                    return {
                      ...row,
                      position: i,
                      row_order: i + 1,
                      row_id: `row-${type}-${selectedItem?.id}-${i + 1}`,
                    };
                  } else {
                    return row;
                  }
                });

                // Reorder rows based on the new updated list
                // console.log('adjustedRows', adjustedRows);
                // refetch?.();
                // refetchBudgetId?.();
                reorderRows(adjustedRows);
                refetch?.();
                refetchBudgetId?.();
                return {
                  ...prev,
                  [selectedItem?.id]: updatedRows,
                };
              });
              // Recalculate total after row duplication
              const totalCost = calculateDetailsTotal(
                [...detailsRows[selectedItem?.id], newRow],
                setDetailsTotal
              );
              updateSwimlaneCosts(totalCost, selectedItem);
              refetch?.();
              refetchBudgetId?.();
            },
            onError: () => {
              toast.error('Error in duplicating item');
            },
          }
        );
      }
    },
    [
      detailsRows,
      deleteSwimlaneDetailsMutation,
      updateSwimlaneCosts,
      refetchBudgetId,
      selectedItem,
      type,
      id,
      queryClient,
    ]
  );

  const handleKeyUp = event => {
    const key = event.key;
    const cursorPosition = event.target.selectionStart;
    const value = event.target.value;
    const name = event.target.name;
    const columnkey = name?.split(',')[0];
    const index = parseInt(name?.split(',')[1]);
    console.log(cursorPosition, value, columnkey, index, event);
    if (
      key === 'ArrowDown' ||
      key === 'ArrowUp' ||
      key === 'ArrowRight' ||
      key === 'ArrowLeft' ||
      key === 'Enter'
    ) {
      const newCaretPosition = name + ',' + cursorPosition;
      const container = document.getElementsByClassName(
        'budget-swimlane-details-container'
      )?.[0];
      let elem;
      switch (key) {
        case 'ArrowDown':
          elem = document.getElementsByName(columnkey + ',' + (index + 1))?.[0];
          elem &&
            container?.scrollBy({
              top: 40,
              behavior: 'smooth',
            });
          break;
        case 'ArrowUp':
          elem = document.getElementsByName(columnkey + ',' + (index - 1))?.[0];
          elem &&
            container?.scrollBy({
              top: -40,
              behavior: 'smooth',
            });
          break;
        case 'ArrowLeft':
          if (
            caretPoition === newCaretPosition ||
            value.length === cursorPosition ||
            value.length === 0
          ) {
            if (columnkey === 'comment') {
              elem = document.getElementsByName(
                'multiplier_unit' + ',' + index
              )?.[0];
            } else if (columnkey === 'multiplier_unit') {
              elem = document.getElementsByName(
                'multiplier' + ',' + index
              )?.[0];
            } else if (columnkey === 'multiplier') {
              elem = document.getElementsByName('rate' + ',' + index)?.[0];
            } else if (columnkey === 'rate') {
              elem = document.getElementsByName('unit' + ',' + index)?.[0];
            } else if (columnkey === 'unit') {
              elem = document.getElementsByName('quantity' + ',' + index)?.[0];
            } else if (columnkey === 'quantity') {
              elem = document.getElementsByName('item' + ',' + index)?.[0];
            } else if (columnkey === 'item') {
              onKeyUp?.(event);
              return;
            }
          } else {
            setCaretPosition(newCaretPosition);
          }
          break;
        case 'ArrowRight':
          if (
            caretPoition !== null &&
            (caretPoition === newCaretPosition || value.length === 0)
          ) {
            if (columnkey === 'item') {
              elem = document.getElementsByName('quantity' + ',' + index)?.[0];
            } else if (columnkey === 'quantity') {
              elem = document.getElementsByName('unit' + ',' + index)?.[0];
            } else if (columnkey === 'unit') {
              elem = document.getElementsByName('rate' + ',' + index)?.[0];
            } else if (columnkey === 'rate') {
              elem = document.getElementsByName(
                'multiplier' + ',' + index
              )?.[0];
            } else if (columnkey === 'multiplier') {
              elem = document.getElementsByName(
                'multiplier_unit' + ',' + index
              )?.[0];
            } else if (columnkey === 'multiplier_unit') {
              elem = document.getElementsByName('comment' + ',' + index)?.[0];
            }
            if (elem?.value?.length !== 0) {
              setCaretPosition(null);
            }
          } else {
            setCaretPosition(newCaretPosition);
          }
          break;
        case 'Enter':
          console.log('EnterKey', key);
          event.preventDefault();
          // Insert new row below the current row
          if (index + 1 != detailsRows[selectedItem?.id].length - 1) {
            const newRowData = {
              row_order: index + 1,
              item: '',
              position: index,
              multiplier: '1',
              quantity: '1',
              row_id: `row-${type}-${selectedItem?.id}-${index + 1}`,
              // Add any other required properties for the new row here
            };

            createSwimlaneDetailsMutation.mutate(
              {
                details: { ...newRowData },
                selectedItem,
                swimlane,
                type,
              },
              {
                onSuccess: response => {
                  const newRow = response.data || newRowData;
                  setDetailsRow(prev => {
                    const updatedRows = [...prev[selectedItem?.id]];
                    console.log('updatedRows', updatedRows);
                    updatedRows.splice(index + 1, 0, newRow);
                    console.log('updatedRows', updatedRows);
                    // Adjust positions after inserting the new row
                    const adjustedRows = updatedRows.map((row, i) => {
                      if (row?.id) {
                        return {
                          ...row,
                          position: i,
                          row_order: i + 1,
                          row_id: `row-${type}-${selectedItem?.id}-${i + 1}`,
                        };
                      } else {
                        return row;
                      }
                    });

                    console.log('adjustedRows', adjustedRows);
                    reorderRows(adjustedRows);
                    container?.scrollBy({
                      top: 40,
                      behavior: 'smooth',
                    });
                    setTimeout(() => {
                      document
                        .getElementsByName(`item,${index + 1}`)[0]
                        ?.focus();
                    }, 0);
                    return {
                      ...prev,
                      [selectedItem?.id]: updatedRows,
                    };
                  });
                  refetch?.();
                  refetchBudgetId?.();
                },
                onError: () => {
                  toast.error('Error in adding new row');
                },
              }
            );
          } else {
            // console.log('last row', index);
            container?.scrollBy({
              top: 40,
              behavior: 'smooth',
            });
            setTimeout(() => {
              document.getElementsByName(`item,${index + 1}`)[0]?.focus();
            }, 0);
          }
          break;
        default:
      }
      elem?.focus();
      elem?.select();
    }
  };

  const onDragStart = result => {
    console.log('onDragStart', result);
    setHiddenColumn({ ...hiddenColumn, comment: false });
  };

  const onDragEnd = result => {
    setHiddenColumn({ ...hiddenColumn, comment: true });

    console.log('result', result);
    if (!result.destination) return;

    const swimlane_item_Lable = detailsRows[selectedItem?.id][0]?.row_id
      ?.includes('row-swimlane')
      .toString();
    const line_item_Lable = detailsRows[selectedItem?.id][0]?.row_id
      ?.includes('row-line-item')
      .toString();

    console.log('swimlane_item_Lable', swimlane_item_Lable);
    console.log('line_item_Lable', line_item_Lable);

    const reorderedItems = Array.from(detailsRows[selectedItem?.id]);
    const nonDraggableItemIndex = detailsRows[selectedItem?.id].findIndex(
      item => item.id === undefined
    );

    let nonDraggableItem;
    if (nonDraggableItemIndex !== -1) {
      [nonDraggableItem] = reorderedItems.splice(nonDraggableItemIndex, 1);
    }

    const [movedItem] = reorderedItems.splice(result.source.index, 1);
    reorderedItems.splice(result.destination.index, 0, movedItem);

    if (nonDraggableItem) {
      reorderedItems.push(nonDraggableItem);
    }

    const payload = [];
    reorderedItems.forEach((item, index) => {
      if (item?.id) {
        payload.push({ id: item.id, position: index });
      }
    });

    console.log('reorderedItems', reorderedItems);

    setDetailsRow(prev => ({
      ...prev,
      [selectedItem?.id]: reorderedItems,
    }));

    if (swimlane_item_Lable === 'true') {
      console.log('swimlane_item_Lable:', swimlane_item_Lable);
      useReorderDetailSheet.mutate(
        { detail_sheet_items: payload },
        {
          onSuccess: response => {
            console.log('response@11', response);
            refetchBudgetId?.();
            refetch?.();
          },
          onError: () => {
            console.log('error@11');
            toast.error('Error in reordering');
          },
        }
      );
    }

    if (line_item_Lable === 'true') {
      console.log('line_item_Lable:', line_item_Lable);
      useReorderDetailLineItemSheet.mutate(
        { detail_lineitem_sheet_items: payload },
        {
          onSuccess: response => {
            console.log('response@22', detailsRows);
            console.log('response@22', response);
            refetchBudgetId?.();
            refetch?.();
          },
          onError: () => {
            console.log('error@22');
            toast.error('Error in reordering');
          },
        }
      );
    }
  };
  useEffect(() => {
    let swimlaneChanged = false;
    console.log('selectedItem', selectedItem);
    if (selectedItem?.id !== selectedSwimlane?.id) {
      setSelectedSwimlane(selectedItem);
      swimlaneChanged = true;
      const updatedDetails = handlePartialDataUpdate(
        // swimlaneChanged ? [] : detailsRows[selectedItem?.id],
        detailsRows[selectedItem?.id] || [],
        details,
        selectedItem,
        type
      );
      console.log('updatedDetails', updatedDetails);
      calculateDetailsTotal([...updatedDetails], setDetailsTotal);
      setDetailsRow(prev => ({
        ...prev,
        [selectedItem?.id]: [...updatedDetails],
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [details, selectedItem, type, detailsRows, onDragEnd]);

  // const handleKeyDown = event => {
  //   console.log('Enter');
  //   console.log('detailsLength', detailsRows[selectedItem?.id].length);
  //   const key = event.key;
  //   console.log('key', key);
  //   const index = parseInt(event.target.name.split(',')[1]);
  //   if (key === 'Enter') {
  //     console.log('EnterKey', key);
  //     event.preventDefault();
  //     // Insert new row below the current row
  //     if (index + 1 != detailsRows[selectedItem?.id].length - 1) {
  //       const newRowData = {
  //         row_order: index + 1,
  //         item: '',
  //         position: index + 1,
  //         multiplier: '1',
  //         quantity: '1',
  //         row_id: `row-${type}-${selectedItem?.id}-${index + 1}`,
  //         // Add any other required properties for the new row here
  //       };

  //       createSwimlaneDetailsMutation.mutate(
  //         {
  //           details: { ...newRowData },
  //           selectedItem,
  //           swimlane,
  //           type,
  //         },
  //         {
  //           onSuccess: response => {
  //             const newRow = response.data || newRowData;
  //             setDetailsRow(prev => {
  //               const updatedRows = [...prev[selectedItem?.id]];
  //               // console.log('updatedRows', updatedRows);
  //               updatedRows.splice(index + 1, 0, newRow);

  //               // Adjust positions after inserting the new row
  //               const adjustedRows = updatedRows.map((row, i) => ({
  //                 ...row,
  //                 position: i,
  //                 row_order: i + 1,
  //                 row_id: `row-${type}-${selectedItem?.id}-${i + 1}`,
  //               }));
  //               // console.log('adjustedRows', adjustedRows);
  //               reorderRows(adjustedRows);
  //               // setTimeout(() => {
  //               //   document.getElementsByName(`item,${index + 1}`)[0]?.focus();
  //               // }, 0);
  //               return {
  //                 ...prev,
  //                 [selectedItem?.id]: adjustedRows,
  //               };
  //             });
  //           },
  //           onError: () => {
  //             toast.error('Error in adding new row');
  //           },
  //         }
  //       );
  //     } else {
  //       console.log('last row', index);
  //       setTimeout(() => {
  //         document.getElementsByName(`item,${index + 1}`)[0]?.focus();
  //       }, 0);
  //     }
  //   }
  // };

  return (
    <DragDropContext onDragEnd={onDragEnd} onDragStart={onDragStart}>
      <Droppable droppableId="droppable">
        {provided => (
          <Table
            striped
            bordered
            hover
            className="detailed-view-table"
            {...provided.droppableProps}
            ref={provided.innerRef}
          >
            <thead>
              <tr>
                {columns?.map(column => (
                  <th
                    key={column.key}
                    style={
                      hiddenColumn[column.key]
                        ? { width: '40px' }
                        : { width: column.width }
                    }
                    className={column.key}
                    onClick={column.onClick}
                  >
                    <div>
                      {!hiddenColumn[column.key] && column.label}
                      {column.icon}
                    </div>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {detailsRows[selectedItem?.id]?.map(
                (rowData, index) => (
                  console.log('rowData#', rowData?.id),
                  (
                    <Draggable
                      key={rowData.id || rowData.row_id}
                      draggableId={(rowData.id || rowData.row_id).toString()}
                      index={index}
                      isDragDisabled={rowData?.id == undefined ? true : false}
                    >
                      {(provide, snapshot) => (
                        <tr
                          ref={provide.innerRef}
                          {...provide.draggableProps}
                          {...provide.dragHandleProps}
                          // style={getItemStyle(
                          //   snapshot.isDragging,
                          //   provide.draggableProps.style
                          // )}
                        >
                          <ItemRow
                            callHiddenCommentParent={callHiddenCommentParent}
                            // key={rowData?.row_id}
                            key={rowData?.id || rowData?.row_id || index}
                            columns={columns}
                            rowData={rowData}
                            addRow={addRow}
                            onKeyUp={handleKeyUp}
                            // onKeyDown={handleKeyDown}
                            index={index}
                            lastIndex={detailsRows[selectedItem?.id].length - 1}
                            // lastObjectId={
                            //   rowData?.id == lastObjectId ? lastObjectId : 0
                            // }
                            hiddenColumn={hiddenColumn}
                            expandColumn={expandColumn}
                            canEdit={hasEditAccess()}
                            addEmptyRow={addEmptyRow}
                            handleRowAction={handleRowAction}
                            hideOptions={Boolean(
                              index + 1 ===
                                detailsRows[selectedItem?.id].length &&
                                !rowData?.id
                            )}
                            focusEl={focusEl}
                            setFocusEl={setFocusEl}
                          />
                        </tr>
                      )}
                    </Draggable>
                  )
                )
              )}
              {provided.placeholder}
            </tbody>
            <tfoot className="detailed-view-table-foot">
              <tr>
                <td colSpan="7">
                  {selectedItem?.name} Sub-Total:{' '}
                  {formatCurrency(
                    detailsTotal || 0,
                    selectedBudget?.primary_currency
                  )}
                </td>
                <td
                  className="sub-total-row-fill"
                  style={
                    hiddenColumn['comment']
                      ? { width: '40px' }
                      : { minWidth: '250px' }
                  }
                >
                  &nbsp;
                </td>
              </tr>
            </tfoot>
          </Table>
        )}
      </Droppable>
    </DragDropContext>
  );
};

DetailedView.propTypes = {
  setNavWidth: PropTypes.func,
  onKeyUp: PropTypes.func,
};

DetailedView.defaultProps = {
  setNavWidth: null,
  onKeyUp: null,
};

export default DetailedView;
