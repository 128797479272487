import FormInput from 'components/FormInput';
import PropTypes from 'prop-types';
import React, { useState, useEffect, useCallback, useRef } from 'react';
import TypeAheadInput from 'components/TypeAheadInput';
import { ReactComponent as MoreIcon } from 'assets/images/more-vert.svg';
import DropDownMenu from 'components/DropDownMenu';
import { calculateItemTotal } from 'utils/common';
import { fixNumber, covertTo2Digit, covertTo4Digit } from 'utils/helper';
import pluralize from 'pluralize';
import { useHomeScreenContext } from 'layouts/HomeScreenLayout/contexts/HomeScreenContext';

const ItemRow = ({
  columns,
  rowData,
  addRow,
  hiddenColumn,
  onKeyUp,
  onKeyDown,
  lastIndex,
  lastObjectId,
  index,
  callHiddenCommentParent,
  expandColumn,
  canEdit,
  handleRowAction,
  addEmptyRow,
  hideOptions,
  focusEl,
  setFocusEl,
}) => {
  const lineItemOptions = [
    {
      value: 'delete-item',
      label: 'Delete Line Item',
    },
    {
      value: 'duplicate-item',
      label: 'Duplicate Line Item',
    },
  ];

  const { tabActive, setTabActive } = useHomeScreenContext();

  const hasData = useCallback((row, fieldKey) => {
    const field = columns.find(col => col.key === fieldKey);
    if (!field) return true;

    const hasVal =
      field.dataType === 'decimal'
        ? !Number.isNaN(fixNumber(fixNumber(row[field.key]))) &&
          parseFloat(fixNumber(row[field.key])) >= 0
        : row[field.key];
    return Boolean(row.id || hasVal);
  }, []);

  const [formControlValue, setFormControlValue] = useState('');
  const [addNewRow, setAddNewRow] = useState(true);
  const [newRow, setNewRow] = useState({});
  const [showRowId, setRowId] = useState(null);
  const [unitSuggestions, setUnitSuggestions] = useState([]);

  const processDataToAdd = itemRow =>
    Object.keys(itemRow)
      ?.filter(fieldKey => hasData(itemRow, fieldKey))
      ?.reduce((acc, fieldKey) => {
        const colInfo = columns.find(col => col.key === fieldKey);

        return {
          ...acc,
          [fieldKey]:
            colInfo?.dataType === 'decimal'
              ? covertTo4Digit(fixNumber(itemRow[fieldKey]))
              : itemRow[fieldKey],
        };
      }, {});

  useEffect(() => {
    !(newRow?.id && newRow.row_id) &&
      setNewRow({
        ...(!newRow.row_id ? rowData : { ...newRow, id: rowData?.id }),
        ...(newRow.row_id
          ? null
          : columns.reduce((acc, col) => {
              if (hasData(rowData, col.key)) {
                acc[col.key] =
                  col?.formatValue && col.key !== 'item_total'
                    ? col?.formatValue(rowData[col.key])
                    : rowData[col.key];
              }
              return acc;
            }, {})),
      });

    if (focusEl) {
      const element = document.getElementsByName(focusEl)?.[0];
      console.log('rendering focusEl', element);
      element?.focus();
      setFocusEl(null);
    }
  }, [rowData, hasData, focusEl]);

  const callHiddenComment = () => {
    callHiddenCommentParent();
  };

  const fixedDecimalRate = value => {
    if (value) {
      const isNegative = /^-/.test(value);
      let filteredInput = value.toString().replace(/[^\d.]/g, '');
      const parts = filteredInput.split('.');
      // Remove leading zeros from the integer part
      // parts[0] = parts[0].replace(/^0+/, '') || '0';
      // If there's a decimal part, truncate it to 4 digits
      if (parts.length > 1) {
        parts[1] = parts[1].substring(0, 4);
        filteredInput = parts[0] + '.' + parts[1];
      } else {
        filteredInput = parts[0];
      }
      return (isNegative ? '-' : '') + filteredInput;
    }
    return value;
  };

  const formatRateWithCommas = value => {
    if (value) {
      const isNegative = /^-/.test(value);
      const filteredInput = value.toString().replace(/[^\d.]/g, '');
      const [integer, decimal] = filteredInput.split('.');
      // Remove leading zeros from the integer part
      const formattedInteger = integer.replace(/^0+/, '') || '0';
      // Add commas to the integer part
      const formattedWithCommas = formattedInteger.replace(
        /\B(?=(\d{3})+(?!\d))/g,
        ','
      );
      return (
        (isNegative ? '-' : '') +
        (decimal ? `${formattedWithCommas}.${decimal}` : formattedWithCommas)
      );
    }
    return value;
  };

  const handleNewRowChange = field => event => {
    if (!canEdit) return false;
    // setKeyStore(field.key);
    let value = field?.type === 'text' ? event.target.value : event;
    value = field?.dataType === 'decimal' ? fixedDecimalRate(value) : value;

    const itemRow = {
      ...newRow,
      quantity: Number.isNaN(newRow.quantity)
        ? 1
        : parseFloat(newRow.quantity >= 0 ? newRow.quantity : 1),
      multiplier: Number.isNaN(newRow.multiplier)
        ? 1
        : parseFloat(newRow.multiplier >= 0 ? newRow.multiplier : 1),
      [field?.key]: value,
    };

    itemRow.item_total = calculateItemTotal(itemRow);
    setNewRow(itemRow);

    if (field?.pluralize && itemRow[field?.pluralize?.key]) {
      if (itemRow[field?.pluralize?.key] === newRow[field?.pluralize?.key]) {
        if (itemRow.quantity <= 1) {
          itemRow.unit = itemRow.unit.replace(/s$/, '');
        } else {
          itemRow.unit = pluralize(itemRow.unit, itemRow.quantity);
        }
        if (itemRow.multiplier <= 1) {
          itemRow.multiplier_unit = itemRow.multiplier_unit?.replace(/s$/, '');
        } else {
          itemRow.multiplier_unit = pluralize(
            itemRow.multiplier_unit,
            itemRow.multiplier
          );
        }
        const dataToAdd = processDataToAdd(itemRow);

        const hasDataToPost = columns?.some(col =>
          Object.hasOwn(dataToAdd, col.key)
        );

        if (hasDataToPost) {
          addRow(dataToAdd);
          return setAddNewRow(itemRow);
        }
      } else {
        itemRow[field?.pluralize?.key] = pluralize(
          itemRow[field?.pluralize?.key],
          itemRow[field?.pluralize?.valueKey] || 1
        );
        setUnitSuggestions([]);
        return setNewRow(itemRow);
      }
    }
    const dataToAdd = processDataToAdd(itemRow);

    const hasDataToPost = columns?.some(col =>
      Object.hasOwn(dataToAdd, col.key)
    );
    if (addNewRow && !newRow.id && hasDataToPost) {
      addEmptyRow();
      setAddNewRow(false);
    }
    if (value?.toString() !== newRow[field.key]?.toString() && hasDataToPost) {
      addRow(dataToAdd);
    }
  };
  const handleOnBlur = field => event => {
    // setKeyStore('');
    const value = event?.target?.value || field?.defaultValue;

    const itemRow = {
      ...newRow,
      [field?.key]: field.formatValue ? field.formatValue(value) : value,
    };
    if (field?.key === 'quantity') {
      itemRow.quantity = Number.isNaN(itemRow.quantity)
        ? 1
        : parseFloat(itemRow.quantity >= 0 ? itemRow.quantity : 1);
    }
    if (field?.key === 'multiplier') {
      itemRow.multiplier = Number.isNaN(itemRow.multiplier)
        ? 1
        : parseFloat(itemRow.multiplier >= 0 ? itemRow.multiplier : 1);
    }

    if (field?.key === 'rate') {
      itemRow[field?.key] = formatRateWithCommas(value);
    }

    if (field?.pluralize && itemRow[field?.pluralize?.key]) {
      if (itemRow[field?.pluralize?.key] == newRow[field?.pluralize?.key]) {
        if (itemRow.quantity <= 1) {
          itemRow.unit = itemRow.unit.replace(/s$/, '');
        } else {
          itemRow.unit = pluralize(itemRow.unit, itemRow.quantity);
        }
        if (itemRow.multiplier <= 1) {
          itemRow.multiplier_unit = itemRow.multiplier_unit.replace(/s$/, '');
        } else {
          itemRow.multiplier_unit = pluralize(
            itemRow.multiplier_unit,
            itemRow.multiplier
          );
        }
        const dataToAdd = processDataToAdd(itemRow);
        itemRow[field?.pluralize?.key] = pluralize(
          itemRow[field?.pluralize?.key],
          itemRow[field?.pluralize?.valueKey] || 1
        );
        setUnitSuggestions([]);
        const hasDataToPost = columns?.some(col =>
          Object.hasOwn(dataToAdd, col.key)
        );

        if (hasDataToPost) {
          addRow(dataToAdd);
          return setAddNewRow(itemRow);
        }
      } else {
        itemRow[field?.pluralize?.key] = pluralize(
          itemRow[field?.pluralize?.key],
          itemRow[field?.pluralize?.valueKey] || 1
        );
        setUnitSuggestions([]);
        return setNewRow(itemRow);
      }
    }
    if (field.key === 'multiplier' || field?.key === 'quantity') {
      itemRow.item_total = calculateItemTotal(itemRow);
      const dataToAdd = processDataToAdd(itemRow);

      const hasDataToPost = columns?.some(col =>
        Object.hasOwn(dataToAdd, col.key)
      );

      hasDataToPost && addRow(dataToAdd);
    }
    return setNewRow(itemRow);
  };

  const handleRateOnFocus = field => event => {
    const value = event?.target?.value || field?.defaultValue;
    const itemRow = {
      ...newRow,
      [field?.key]: fixedDecimalRate(value),
    };
    setNewRow(itemRow);
  };

  useEffect(() => {
    if (newRow.multiplier_unit) {
      setUnitSuggestions([newRow.unit]);
    }
  }, [newRow.multiplier_unit]);
  useEffect(() => {
    setTabActive('');
  }, []);
  // useEffect(() => {
  //   if (keyStore) {
  //     console.log('newRow', keyStore);
  //     console.log('byname', `${keyStore},${lastIndex - 1}`);
  //     // const in_dex = lastIndex - 1;
  //     // if (keyStore === 'comment') {
  //     //   console.log('in', in_dex);
  //     //   setTimeout(() => {
  //     //     document.getElementsByName(`${keyStore},${in_dex}`)[0]?.focus();
  //     //   }, 0);
  //     // }
  //     setTimeout(() => {
  //       const inputElement = document.getElementsByName(
  //         `${keyStore},${lastIndex - 1}`
  //       )[0];
  //       if (inputElement) {
  //         inputElement.focus();
  //         // Optionally select the text to make it easier for the user to continue typing
  //         inputElement.setSelectionRange(
  //           inputElement.value.length,
  //           inputElement.value.length
  //         );
  //         // setKeyStore('');
  //       }
  //     }, 100); // Increase the delay to 200ms
  //   }
  // }, [rowData, rowData?.id]);

  return (
    <>
      {columns?.map(column => {
        return (
          <td
            key={`new-row && ${column?.key}`}
            style={
              hiddenColumn[column.key]
                ? { width: '40px' }
                : { width: column.width }
            }
            className={column?.key}
            onFocus={
              column?.key === 'comment' ? expandColumn(column.key) : undefined
            }
          >
            <div className="col-content">
              {column.prefix && <div className="prefix">{column.prefix}</div>}
              {column?.type === 'text' && (
                <FormInput
                  inputClassName={
                    hiddenColumn?.comment === true
                      ? 'form-input-group-collapsed'
                      : 'form-input-group-expanded'
                  }
                  callHiddenComment={callHiddenComment}
                  hiddenColumn={hiddenColumn}
                  placeholder={column.placeholder}
                  onChange={handleNewRowChange(column)}
                  editComment={handleNewRowChange(column)}
                  onKeyUp={onKeyUp}
                  onKeyDown={onKeyDown}
                  name={column.key + ',' + index}
                  value={newRow?.[column.key]}
                  readOnly={!canEdit}
                  isComment={column.key}
                  onBlur={handleOnBlur(column)}
                  onFocus={handleRateOnFocus(column)}
                  style={{ background: 'transparent' }}
                  formControlValue={formControlValue}
                  itemType={column?.key}
                  rowData={newRow}
                  rowId={newRow?.id}
                />
              )}
              {column?.type === 'select' && (
                <TypeAheadInput
                  placeholder={column.placeholder}
                  options={
                    unitSuggestions.length > 0
                      ? unitSuggestions
                      : column.options
                  }
                  onChange={handleNewRowChange(column)}
                  defaultValue={newRow?.[column.key] || ''}
                  // onKeyDown={e => onKeyDown(e)}
                  onKeyUp={onKeyUp}
                  name={column.key + ',' + index}
                  readOnly={!canEdit}
                  onBlur={handleOnBlur(column)}
                />
              )}
              {column?.key === 'item_total' && (
                <div className="prefix">
                  {column?.formatValue(newRow?.[column.key])}
                </div>
              )}
              {canEdit && column?.key === 'item' && !hideOptions && (
                <DropDownMenu
                  menuOptions={lineItemOptions}
                  toggleIcon={<MoreIcon className="more-icon" />}
                  onChange={handleRowAction(newRow)}
                />
              )}
            </div>
          </td>
        );
      })}
    </>
  );
};

ItemRow.propTypes = {
  columns: PropTypes.array.isRequired,
  rowData: PropTypes.object,
  addRow: PropTypes.func.isRequired,
  hiddenColumn: PropTypes.object.isRequired,
  callHiddenCommentParent: PropTypes.func,
  expandColumn: PropTypes.func,
  canEdit: PropTypes.bool.isRequired,
  handleRowAction: PropTypes.func.isRequired,
  addEmptyRow: PropTypes.func.isRequired,
  hideOptions: PropTypes.bool,
  onKeyUp: PropTypes.func,
  onKeyDown: PropTypes.func,
  index: PropTypes.number.isRequired,
  lastIndex: PropTypes.number.isRequired,
  lastObjectId: PropTypes.number.isRequired,
  focusEl: PropTypes.node,
  setFocusEl: PropTypes.func,
};

ItemRow.defaultProps = {
  rowData: {},
  expandColumn: () => null,
  callHiddenCommentParent: () => null,
  hideOptions: false,
  onKeyUp: e => e,
  onKeyDown: e => e,
  focusEl: null,
  setFocusEl: () => null,
};
export default React.memo(ItemRow);
